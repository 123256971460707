.OCRA.wf-row {
	max-width: 100rem;
	margin: 0 auto;
}

.OCRA .wf-gn-header {
	background-color: transparent;
	margin-top: 1rem;
	height: 2rem;
}

.OCRA .wf-gn a.wf-gn-view__skip-link:focus {
	color: #00558e;
}

.OCRA .wf-gn-header .wf-gn-header__logo .wf-gn-header__logo-icon {
	display: none;
}

.OcraHeaderIconBac {
	background: url(../image-assets/bac-logo.gif) no-repeat;
	background-size: auto 3.5rem;
}

.OcraHeaderIconCisco {
	background: url(../image-assets/cisco-logo.gif) no-repeat;
	background-size: auto;
}

.OcraHeaderIconPolaris {
	background: url(../image-assets/polaris-logo.gif) no-repeat;
	background-size: auto 3.5rem;
}

.OcraHeaderIconRev {
	background: url(../image-assets/rev-logo.png) no-repeat;
	background-size: auto;
}

.OcraHeaderIconHus {
	background: url(../image-assets/hfs-logo.gif) no-repeat;
	background-size: auto 3.5rem;
}

.OcraHeaderIconKmf {
	background: url(../image-assets/kmf-logo.gif) no-repeat;
	background-size: auto 3.5rem;
}

.OcraHeaderIconBcfFr {
	background: url(../image-assets/bcf-logo-fr.png) no-repeat;
	background-size: auto;
}

.OcraHeaderIconBcfEn {
	background: url(../image-assets/bcf-logo.png) no-repeat;
	background-size: auto;
}

.OcraHeaderIconWells {
	background: url(../image-assets/ocra-logo.png) no-repeat;
	background-size: auto 4.5rem;
}

.OcraHeaderIcon {
	height: 5rem;
	margin-left: 1.25rem;
	margin-bottom: 2rem;
}

.OCRA .wf-page-header {
	display: none;
}

.OCRA .HeaderImage {
	width: 100%;
	height: 25rem;
}

.OCRA .HeaderMenu {
	margin-right: 1rem;
	justify-content: flex-end;
	width: 9.375rem;
}

.OCRA .Containers {
	padding: 1.5rem 0;
	margin: 2.5rem auto 0;
	justify-content: center;
}

.OCRA .ForgetLinks {
	padding-top: 0;
}

.OCRA .Containers .container {
	border: 0;
	height: auto;
}

.OCRA .Containers .container .CreditFacilityClick {
	margin-top: -0.17rem;
}
.OCRA .Containers .container input,
.OCRA .Containers .container #captcha-wrapper,
.OCRA .Containers .container #nucaptcha-answer {
	max-width: 25rem;
}

.OCRA .Containers .container .NuDataContainer:empty {
	display: none;
}

.OCRA .Footer {
	padding: 4rem;
	text-align: center;
	background-color: black;
}

.OCRA .Footer p {
	color: white;
}

.OCRA .footer-disclaimer-link {
	margin-right: 0.25rem;
	margin-bottom: 0.063rem;
	color: white;
	text-decoration: underline;
}

.OCRA .Footer p a.footer-disclaimer-link:focus {
	color: #5095ff;
}

.OCRA .Footer p a.footer-disclaimer-link:focus span.wf-button__label {
	color: #5095ff;
}

.OCRA .Footer p a.footer-disclaimer-link:hover {
	color: #5095ff;
}

.OCRA .Footer p a.footer-disclaimer-link:hover span.wf-button__label {
	color: #5095ff;
}

.DeliveryMode .Disclaimer {
	font-size: 0.719rem;
	color: #585858;
}

.OCRA .marginTop1 {
	margin-top: 0.875rem;
}

.OCRA .PaddingTop3 {
	margin-top: 3rem;
}

.OCRA_TermsOfUse {
	height: 60%;
}

.OCRA_DeliveryMode {
	width: 50rem;
}

.OCRA_CreateUserId {
	height: 100%;
	width: 60%;
}

.OCRA_CreateUserId .Country legend {
	margin: 0;
	font-size: 0.875rem;
	color: #5e5e5e;
	padding-right: 0.9375rem;
	padding-bottom: 0.25rem;
	line-height: 1.14;
	display: inline-block;
}

.OCRA_CreateUserId .Country {
	padding-top: 0;
	margin: 0;
	margin-top: 1.5rem;
}

.OCRA_CreateUserId .Country .wf-fieldset__fields-container {
	display: flex;
}

.OCRA .footer-link-container,
#ocraFaqs .footer-link-container {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.OCRA .footer-link-container .footer-link.wf-button--simple,
#ocraFaqs .footer-link-container .footer-link.wf-button--simple {
	padding-left: 0.3rem;
	padding-right: 0.3rem;
	white-space: pre-wrap;
}

.OCRA .Footer > p:last-of-type {
	margin-bottom: 0;
}

.OCRAHeaderContainer {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.OCRA_Header {
	background: url(../image-assets/ocra-header.jpg) no-repeat right top;
	background-size: cover;
	height: 30.938rem;
	position: relative;
}

.HeaderOverlay {
	font-family: 'Wells Fargo Sans', Arial, Sans-Serif;
	background: linear-gradient(
		45deg,
		rgba(49, 49, 49, 1) 0%,
		rgba(66, 73, 75, 0.6) 59%
	);
	display: block;
	padding: 3.125rem;
	color: #fff;
	font-size: 1.125rem;
	max-width: 42.7%;
	position: absolute;
	margin-left: 5%;
}

/*targetting only IE11*/
_:-ms-fullscreen,
:root .HeaderOverlay {
	top: 20%;
	left: 1%;
}

_:-ms-fullscreen,
:root .OCRA .footer-link-container {
	display: block;
	text-align: center;
}

.HeaderOverlay h1 {
	margin-top: 0;
	font-size: 2.125rem;
	margin-bottom: 0;
	font-weight: 600;
	color: #fff;
	line-height: 2.5rem;
}

.HeaderOverlay h1:after {
	display: table;
	content: '';
	border-top: 0.313rem solid #fbc419;
	width: 4.688rem;
	margin: 0.375rem 0 1rem 0;
}
.HeaderOverlay p {
	color: #fff;
	font-size: 1.125rem;
}
.OCRA .NoSignUpMessageContainer,
.OCRA .CreateAccountContainer {
	border: 0;
	box-shadow: none;
	margin-left: -0.625rem;
}
.OCRA .LoginContainer {
	border: 0;
	box-shadow: none;
	border-left: 0.063rem solid #f1f0ed;
}
.OCRA .CreateAccountContainer h2,
.OCRA .NoSignUpMessageContainer h2,
.OCRA .LoginContainer h2 {
	font-size: 1.5rem;
	color: #d71e28;
	margin-bottom: 1rem;
}
.OCRA .ApplicationTypeButtons {
	display: inline-block;
}
.OCRA .ApplicationTypeButtons:first-child {
	margin-right: 1.5rem;
}
.OCRA .RightAlign {
	flex-basis: 100%;
	text-align: right;
	max-width: 26.5rem;
}
.OCRA .LoginContainer .RememberMe {
	padding-top: 0;
	margin-bottom: 1rem;
}
.OCRA .SignOnHelp {
	max-width: 27rem;
}
.OCRA .SignOnError {
	color: #bb0700;
}

.OCRA_CreateUserId .PaddingTop3 .middleInitial {
	flex-basis: 100%;
	max-width: 100%;
}
.OCRA_CreateUserId .PaddingTop3 .middleInitial input {
	width: 40%;
}

@media only screen and (max-width: 1024px) {
	.OCRA .footer-link-container,
	#ocraFaqs .footer-link-container {
		flex-wrap: wrap;
	}
	.OCRA .LoginContainer {
		border-left: 0;
	}
	.OCRA_CreateUserId .PaddingTop3 .middleInitial input {
		width: 100%;
	}
}

@media only screen and (max-width: 768px) {
	.OCRA_Header {
		padding-left: 0;
		height: 28.125rem;
	}
	.HeaderOverlay {
		max-width: 100%;
		margin-left: 0;
		background: linear-gradient(
			360deg,
			rgba(49, 49, 49, 1) 60%,
			rgba(66, 73, 75, 0) 100%
		);
		top: -10.938rem;
		margin-bottom: -10.938rem;
		position: relative;
	}
	.OCRA_CreateUserId .PaddingTop3 .middleInitial input {
		width: 100%;
	}
}

@media only screen and (min-width: 1025px) and (max-width: 1600px) {
	.OCRA .CreateAccountContainer,
	.OCRA .NoSignUpMessageContainer {
		margin-left: 0.313rem;
	}
	.OCRA .LoginContainer {
		padding-left: 0.313rem;
	}
	.HeaderOverlay {
		max-width: 42%;
	}
}

@media only screen and (max-width: 600px) {
	.OCRA.wf-row {
		display: block;
	}
	.OCRA .footer-disclaimer-link {
		white-space: normal;
	}
	.OCRA .HeaderMenu .ocra-language-select .wf-select__wrapper button {
		padding: 0 0.5rem;
	}
	.OCRA .wf-gn-header {
		justify-content: space-between;
	}
	.OCRA .Containers .container input,
	.OCRA .Containers .container #captcha-wrapper,
	.OCRA .Containers .container #nucaptcha-answer {
		max-width: none;
	}
	.OCRA .RightAlign {
		max-width: none;
	}
	.OCRA .RegisterButton {
		width: 100%;
	}
	.OCRA .SignOn {
		width: 100%;
	}
	.OCRA .SignOnHelp {
		margin-top: 1.25rem;
		max-width: none;
	}
	.OCRA .LoginContainer,
	.OCRA .NoSignUpMessageContainer,
	.OCRA .CreateAccountContainer {
		margin-top: 1.25rem;
	}
	.OCRA .wf-page-header {
		display: flex;
	}
	.OCRA_CreateUserId .PaddingTop3 .middleInitial input {
		width: 100%;
	}
}
